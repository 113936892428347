@mixin p{
  @include font-size(rem(16), rem(25));
  @extend %letter-spacing;
}

@mixin p--small{
  @include font-size(rem(14), rem(22));
  @extend %letter-spacing;
}

@mixin p--medium{
  @include font-size(rem(20), rem(32));
  @extend %letter-spacing;
}

@mixin p--large{
  @include font-size(rem(24), rem(34));
  @extend %letter-spacing;
}