.fade-out {
    animation-name: fadeOut;
    animation-duration: 500ms;
    animation-timing-function: ease-in-out;
}

.fade-in {
    animation-name: fadeIn;
    animation-duration: 500ms;
    animation-timing-function: ease-in-out;
}
