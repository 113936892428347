/* ==========================================================================
	GLOBAL VARIABLES
/* ========================================================================== */
@import './core/helpers/variables';


/* ==========================================================================
	LIB - PACKAGE
/* ========================================================================== */

@import '../dauphine/dauphine';
@import '../dauphine/starter';
@import '../dauphine/framework';


/* ==========================================================================
	CORE
/* ========================================================================== */
@import './core/mixins/mixins';
@import './core/definitions/definitions';

/* ==========================================================================
	COMPONENTS
/* ========================================================================== */

#root {
  width: 100%;
  min-height: 100vh;
  background-color: $gray-04;
}

body {
  overflow: hidden;
}
