@import '../../assets/styles/core/helpers/inc';

.notification {
  position: absolute;
  z-index:-1;
  top: rem(32);
  right: rem(16);
  z-index: 9;
  width: rem(405);
  background: $gradient-background-reverse;
  padding: rem(32);
  border-radius: rem(8);
  color: $white;
  opacity: 0;
  transform: scale(0.8);
  transition: all 0.12s ease-in;

  &--opened {
    z-index: 999;
    opacity: 1;
    transform: scale(1);
  }

  &__close {
    position: absolute;
    top: rem(16);
    right: rem(16);
    opacity: 0.5;
    cursor: pointer;
    transition: opacity 0.16s;

    &:hover {
      opacity: 0.8;
    }

    svg {
      fill: $white;
    }
  }

  &__title {
    @include p;
    font-weight: bold;
    margin-bottom: rem(4);
  }

  &__text {
    @include p--small;
  }
}