@import '../../assets/styles/core/helpers/inc';

$primary-color: $secondary-02;
@import '~loaders.css/src/animations/square-spin.scss';

.loader-hidden {
    display: none;
}
.loader-active {
    display: block;
}


.dashboard-loader__container {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}