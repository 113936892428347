@import '../../assets/styles/core/helpers/inc';

.filters {
    display: flex;
    align-items: center;
    padding-left: rem(12);
    padding-right: rem(12);

    &__item {
        padding: rem(4) rem(14);
        @include p--small;
        cursor: pointer;

        &--current {
            background-color: $secondary-01;
            color: $white;
            border-radius: rem(50);
        }
    }
}
