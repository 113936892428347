body {
    position: relative;
    &.overflow-hidden {
        overflow: hidden;
    }
}

body[data-aos-easing="ease"] [data-aos], [data-aos][data-aos][data-aos-easing="ease"] {
    transition-timing-function: $animCurbe;
}

[data-aos='fade-up'] {
    transform: $translateTop;
}

hr {
    border-style: solid;
    border-width: rem(0.5);
    border-color: $gray-03;
    margin: 0;
}

#loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $black;
  cursor: wait;
  opacity: 1;
  z-index: 999;
  @include transition-default();

  &.loaded{
    opacity: 0;
    visibility: hidden;
  }
}

@include breakpoint(md) {
    .custom-scrollbar {
        &::-webkit-scrollbar {
            width: rem(14);
            height: rem(18);
        }
        &::-webkit-scrollbar-thumb {
            height: rem(6);
            border: rem(4) solid rgba(0, 0, 0, 0);
            background-clip: padding-box;
            border-radius: rem(7);
            background-color: rgba(0, 0, 0, 0.15);
            box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
        }
        &::-webkit-scrollbar-button {
            width: 0;
            height: 0;
            display: none;
        }
        &::-webkit-scrollbar-corner {
            background-color: transparent;
        }
    }
}
