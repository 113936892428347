@mixin transition-default(){
    @include transition($animation-hover);
}

@mixin transition-color(){
    @include transition($animation-hover, color);
}

@mixin transition($time, $type:all,  $effect:$animation-effect){
    transition: $type $time  $effect;
}

@mixin keyframes($animation) {
    @-webkit-keyframes #{$animation} {
        @content;
    }
    @-moz-keyframes #{$animation} {
        @content;
    }
    @-ms-keyframes #{$animation} {
        @content;
    }
    @-o-keyframes #{$animation} {
        @content;
    }
    @keyframes #{$animation} {
        @content;
    }
}
