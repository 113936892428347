@import '../../assets/styles/core/helpers/inc';

.screensize-modal {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 99;
    height: 100vh;
    width: 100vw;
    background: $gradient-background;

    color: $white;

    &__content {
        max-width: rem(420);
        margin: rem(52);
    }

    .h5, .headline {
        color: $white;
        margin-bottom: rem(8);
    }

    .headline {
        opacity: 0.65;
    }

    .h5 {
        background: transparent;
        -webkit-background-clip: unset;
        -webkit-text-fill-color: $white;
    }

    svg {
        fill: $white;
        margin: auto;
        display: block !important;
        width: rem(44);
        margin-bottom: rem(38);
    }
}

@include breakpoint-max(md) {
    .screensize-modal {
        display: flex;
    }
}