@import '../../assets/styles/core/helpers/inc';

.header {
    position: relative;
    z-index: 9;
    display: flex;
    justify-content: space-between;

    background-color: $white;
    padding: rem(14) rem(16);
    box-shadow: $box-shadow-layout;


    &__actions {
        display: flex;
        align-items: center;
        cursor: pointer;

        &__item {
            margin-left: rem(8);

            &--icon {
                padding: rem(11);
                background-color: $gray-04;
                border-radius: 50%;
                cursor: pointer;
            }
        }
    }

    &__account-info {

        &__name {
            color: $black;
        }

        &__location {
            color: $gray-01;
            font-size: rem(12) !important;
            line-height: rem(19) !important;
        }

    }

    &__language-switch {
        margin-right: rem(32);
        &__item {
            @extend %headings-base;
            text-transform: uppercase;
            color: $gray-02;
            padding: 0 rem(6);
            height: rem(12);
            line-height: rem(12);
            font-size: rem(12);

            &:nth-child( n + 2 ) {
                border-left: 1px solid $gray-02;
            }

            &.active {
                color: $text-color;
            }

        }
    }

    &__profile {
        &__context-menu {
            position: absolute;
            background-color: $white;
            background-color: #fff;
            box-shadow: $box-shadow;

            &__item {
                padding: 10px;
            }
        }
    }

}