@import '../../assets/styles/core/helpers/inc';

.searchbar {
    display: flex;
    position: relative;
    align-items: center;
    width: rem(240);
    height: rem(40);
    padding: 0 rem(14);
    background-color: $gray-03;
    border-radius: rem(40);

    &__input {
        display: block;
        width: 100%;
        height: rem(48);
        font-family: $font-primary;
        color: $primary-color;
        right: 0;
        cursor: text;
        text-decoration: none;
        background-color: transparent;
        line-height: rem(40);
        outline: none;
        border: 0;
        text-align: left;
        white-space: nowrap;
        @include p--small;
        padding-left: rem(12);


        &:focus{
            outline: 0;
        }

        &::placeholder {
            color: $primary-black;
        }
    }

    &__icon {
        &--close {
            cursor: pointer;
        }
    }

}
