/* ===================
      Wysiwyg
/* ===================*/
.wysiwyg{

  //**************** MARGIN **********************//

  & > *:last-child{
    margin-bottom: 0;
  }

  p, ul, ol{
    & + h2, + h3, +h4{
      margin-top: rem(30);
    }
  }

  h2{
    & + h3, + h4{
      margin-top: rem(10);
    }
  }
  h3{
    & + h2, + h4{
      margin-top: rem(5);
    }
  }
  h4{
    & + h3, + h2{
      margin-top: rem(10);
    }
  }

  h2, h3, h4{
    & + ul, + ol, + p{
      margin-top: 10px;
      @include breakpoint(md){
        margin-top: rem(20);
      }
    }
  }

  p{
    & + ul, + ol{
      margin-top: rem(10);
    }
    & + p , & + .btn{
      margin-top: rem(20);
    }
  }

  ul{
    & + ul, + ol{
      margin-top: rem(10);
    }
    & + p, & + .btn{
      margin-top: rem(20);
    }
  }

  ol{
    & + ol, + ul{
      margin-top: rem(10);
    }
    & + p, & + .btn{
      margin-top: rem(20);
    }
  }


  //**************** CUSTOMIZE **********************//

  h1{
    @extend %h1;
  }

  h2 {
    @extend %h2;
  }

  h3 {
    @extend %h3;
  }

  h4 {
    @extend %h4;
  }

  ul{
    li{
      @extend %primary-list;
    }
  }

  ol{
    li{
      @extend %number-list;
    }
  }

  a{
    @include transition-color();
    &:hover{

    }
  }

  p {
    @include p;
  }

  .btn{
    a{
      text-decoration: none;
    }
    &:hover{
      a{
        color: $primary-color;
      }
    }
  }

  hr{
    display: none;
  }
}

/* ===================
	    ON DARK
/* ===================*/
​
.on-dark-bg {
  background:$primary-color;
}
​
.on-dark {
  .h1, .h2, .h3, .h4, .h5,
  p, a, ul li, ol li {
   color:$white;
  }
  a {
    @include breakpoint(md) {
      &:hover {
        color: $secondary-02;
      }
    }
  }
}
