%headings-base {
  display: inline-block;
  font-family: $font-primary;
  color: $primary-black;
  font-weight: 800;
  @extend %letter-spacing;
}

%headings-gradient {
  background: $gradient-primary;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

%h1 {
  @extend %headings-base;
  @extend %headings-gradient;
  @include font-size(rem(42), rem(57));
}

%h2 {
  @extend %headings-base;
  @extend %headings-gradient;
  @include font-size(rem(30), rem(36));
}

%h3 {
  @extend %headings-base;
  @extend %headings-gradient;
  @include font-size(rem(28), rem(34));
}

%h4 {
  @extend %headings-base;
  @extend %headings-gradient;
  @include font-size(rem(24), rem(33));
}

%h5 {
  @extend %headings-base;
  @extend %headings-gradient;
  @include font-size(rem(20), rem(27));
}

%headline {
  @extend %headings-base;
  text-transform: uppercase;
  color: $secondary-01;
}