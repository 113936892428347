@import '../../assets/styles/core/helpers/inc';

.splash-screen {

    background: $gradient-background;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $white;

    &__content {
        transition: all ease-in-out 0.4s;
        width: 300px;
        opacity: 0.9;
        text-align: center;

        .h3 {
            margin-bottom: rem(16);
            color: $white;
            -webkit-text-fill-color: white;
        }

        .headline {
            color: $white;
            opacity: 0.65;
        }

        .h1 {
            background: transparent;
            -webkit-background-clip: unset;
            -webkit-text-fill-color: $white;
        }
    }

   &.loaded {
       .splash-screen__content {
           opacity: 0;
           transform: translateY(-300px);
       }
    }
}

