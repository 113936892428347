.img-homothetic-container {

  @include img-homothetic-container;

  &.landscape {
    > img {
      @include img-landscape;
    }
  }

  &.portrait {
    > img {
      @include img-portrait;
    }
  }

}

.img-container {
  position: relative;
  > img {
    max-width: 100%;
  }
}

.img-full {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover;";
}