%primary-list {
    position: relative;
    padding-left: rem(16);
    @include font-size(rem(16), rem(25));
    @extend %letter-spacing;
    & + li {
        margin-top: rem(16);
    }
    &:before {
        content: "◦";
        position: absolute;
        top: 0;
        left: 0;
    }
}

%number-list {
    position: relative;
    padding-left: rem(30);
    counter-increment: step;
    @include font-size(rem(22), rem(24));
    @include breakpoint(md) {
        @include font-size(rem(22), rem(24));
    }
    & + li {
        margin-top: rem(20);
    }
    &:before {
        content: counter(step) ". ";
        position: absolute;
        top: 0;
        left: 0;
        font-size: rem(18);
    }
}
