.container{
    position: relative;
    @include container(true);
    @include container-max-widths();
}

.container-fluid{
    position: relative;
    @include container(true);
}

.row{
    @include flex-row();

    .no-gutters {
        margin-right: 0;
        margin-left: 0;

        > .col,
        > [class*="col-"] {
          padding-right: 0;
          padding-left: 0;
        }
    }
}

.clearfix{
    @extend %clearfix;
}

.full-link{
    @extend %full;
}

.img-fluid{
    @extend %img-fluid;
}

.container.test{
    position: fixed;
    top: 0;
    transform: translateX(-50%);
    pointer-events: none;
    opacity: .15;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    background: #FF9800;
    z-index: 9999999;
    left: 50%;
    display: flex;
    & > div{
        position: relative;
        margin: 0 12px;
        width: 100%;
        height: 100%;
        background: #009688;
        &:first-child{
            margin-left: 0;
        }
        &:last-child{
            margin-right: 0;
        }
    }
}