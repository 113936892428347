/* ===================
	    Icomoon
/* ===================*/

@mixin icon($content, $size: rem(24), $color: inherit) {
    content: $content;
    font-family: "icomoon";
    font-size: $size;
    color: $color;
    font-weight: 400;
}

/* ===================
	    Image
/* ===================*/

%background-img{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

/* ===================
	    Spacing
/* ===================*/

@function padding($varname) {
  @return rem(map-get($paddings, $varname));
};

/* ===================
	   Padding
/* ===================*/
@mixin padding(){
  padding: padding(xs) 0;
  @include breakpoint(sm) {
    padding: padding(sm) 0;
  }
  @include breakpoint(lg) {
    padding: padding(lg) 0;
  }
  /* @include breakpoint(xlg) {
       padding: padding(xlg) 0;
   }*/
}
@mixin padding-bottom(){
  padding-bottom: padding(xs);
  @include breakpoint(sm) {
    padding-bottom: padding(sm);
  }
  @include breakpoint(lg) {
    padding-bottom: padding(lg);
  }
  @include breakpoint(xlg) {
    padding-bottom: padding(xlg);
  }
}

@mixin padding-top(){
  padding-top: padding(xs);
  @include breakpoint(sm) {
    padding-top: padding(sm);
  }
  @include breakpoint(lg) {
    padding-top: padding(lg);
  }
  @include breakpoint(xlg) {
    padding-top: padding(xlg);
  }
}

/* ===================
	   Margin
/* ===================*/

@mixin margin-top(){
  margin-top: padding(xs);
  @include breakpoint(sm) {
    margin-top: padding(sm);
  }
  @include breakpoint(lg) {
    margin-top: padding(lg);
  }
  @include breakpoint(xlg) {
    margin-top: padding(xlg);
  }
}

@mixin margin-bottom(){
  margin-bottom: padding(xs);
  @include breakpoint(sm) {
    margin-bottom: padding(sm);
  }
  @include breakpoint(lg) {
    margin-bottom: padding(lg);
  }
  @include breakpoint(xlg) {
    margin-bottom: padding(xlg);
  }
}

@mixin margin-top-img(){
  margin-top: rem(30);
  @include breakpoint(sm) {
    margin-top: rem(60);
  }
}

.animated-background {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
  animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: #eeeeee;
  background: -webkit-gradient(linear, left top, right top, color-stop(8%, #eeeeee), color-stop(18%, #dddddd), color-stop(33%, #eeeeee));
  background: -webkit-linear-gradient(left, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  -webkit-background-size: 800px 104px;
  background-size: 800px 104px;
  position: relative;
}

@keyframes placeHolderShimmer{
  0%{
    background-position: -468px 0;
  }
  100%{
    background-position: 468px 0;
  }
}

@mixin scrollbar(){
  @include breakpoint(md){
    &::-webkit-scrollbar {
      width: rem(14);
      height: rem(18);
    }
    &::-webkit-scrollbar-thumb {
      height: rem(6);
      border: rem(4) solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: rem(7);
      background-color: rgba(0, 0, 0, 0.15);
      box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }
    &::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
    }
    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }
  }
}