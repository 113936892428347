@import '../../assets/styles/core/helpers/inc';

.card {
    position: relative;
    display: flex;
    justify-content: center;

    border-radius: rem(8);
    padding: rem(10) 0;
    height: 100%;

    background-color: $white;
    box-shadow: $box-shadow;

    &__bookmark, &__info {
        fill: $gray-02;
        cursor: pointer;
        height: rem(40);
        width: rem(40);
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
    }

    &__bookmark {
        left: 0;
        &--bookmarked {
            fill: $secondary-01;
        }
    }

    &__info {
        right: 0;
    }

    &__icon {
        position: relative;
        padding: rem(10);
        padding-bottom: rem(16);
        height: rem(97);

        &__background {
            position: absolute;
            width: 88px;
            height: 88px;
            top: 50%;
            left: 50%;
            border-radius: 50%;
            transform: translate(-50%, -55%);
            z-index: 0;
            opacity: 0.15;
        }

        img {
            position: relative;
            max-height: rem(64);
        }
    }

    &__title {
        @include p;
        font-weight: bold;
    }

    &__description {
        @include p--small;
        color: $gray-01;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: rem(14) 0;

        text-align: center;
        cursor: pointer;
    }

    &__text {
        padding: 0 rem(14);
        flex: 1 1;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        justify-content: flex-start;
    }

    &.card--highlight {

        padding: rem(10) rem(32);
        justify-content: space-between;

        .card__content {
            flex-direction: row;
            text-align: left;
            align-items: center;
        }

        .card__text {
            padding-left: rem(24);
        }
    }
}
