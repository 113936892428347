@mixin img-homothetic-container {
  position: relative;
  overflow: hidden;
}

@mixin img {
  position: absolute;
  text-align: center;
}

@mixin img-landscape {
  @include img;
  max-width: 100%;
  width: 100%;
  height: auto;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

@mixin img-portrait {
  @include img;
  max-width: none;
  max-height: 100%;
  height: 100%;
  width: auto;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}
