@import '../../assets/styles/core/helpers/inc';


.dashboard {

    &__content {
        position: relative;
        padding: rem(32) rem(48);
        min-height: calc(100vh - #{rem(69)});
        max-height: calc(100vh - #{rem(69)});
        width: 100vw;
        overflow: auto;

        transform: translateY(rem(30));
        opacity: 0;
        transition: all 0.3s ease-in;

        &--loaded {
            transform: none;
            opacity: 1;
        }
    }

    &__title {
        @include flex-col(12);
        margin-bottom: rem(18);
    }

    &__featured-apps {
        margin-bottom: rem(32);
    }

    &__filters {
        margin: 0 0 rem(32) 0;

        .searchbar-container {
            @include col(3);
            margin-left: auto;
            display: flex;
            justify-content: flex-end;
        }
    }

    &__card {

        @include flex-col(6);
        @media (min-width: 600px) {
            @include flex-col(3);
        }
        @media (min-width: 1200px) {
            @include flex-col(2.4);
        }
        @media (min-width: 1600px) {
            @include flex-col(2);
        }

        margin-bottom: rem(24);

        &--highlight {
            @include flex-col(6);
            min-width: rem(400);
            @media (min-width: 1350px) {
                @include flex-col(4);
            }
        }
    }
}

.w-loader {
    .loading-wrapper {
        background-color: transparent !important;
        backdrop-filter: none !important;
    }
}
