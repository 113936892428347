@import '../../assets/styles/core/helpers/inc';

.login {
    background-image: url("../../assets/images/login-background.jpg");
    background-size: cover;
    background-position: 100% center;
    background-repeat: no-repeat;

    &--loaded {
        .login {
            &__content {
                transform: none;
                opacity: 1;
            }
        }
    }

    &__content {
        transform: translateY(rem(30));
        opacity: 0;
        transition: all 0.3s ease-in;

        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;

        align-self: center;
        @include col(4);
        @include offset(1,12);
        color: $white;


        .h1 {
            margin-bottom: rem(16);
        }

        .headline {
            color: $white;
            opacity: 0.65;
        }

        .h1 {
            background: transparent;
            -webkit-background-clip: unset;
            -webkit-text-fill-color: $white;
        }
    }

    &__form {
        @include col(7);
        position: relative;

        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 1112px) {
            background-image: none !important;
        }

        > .w-iframe {
            z-index: 2;
            width: 36vw;
            height: 33vw;
            position: fixed;
            right: 6vw;
            background-color: $white;
            cursor: pointer;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: rem(8);
            > .auth-msal {
                z-index: 3;
                background: white;
            }
        }

        #iframe-sso {
            width: 388px;
            height: 345px;
            z-index: 2;
            position: relative;
            transform: translateY(-25px);
            &.hidden {
                display: none;
            }
            &:before {
                z-index: 3;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 58px;
                background: white;
                content: "";
            }
            &:after {
                z-index: 3;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 28px;
                background: white;
                content: "";
            }
            > iframe {
                width: 388px;
                height: 345px;
            }
        }

        .loader-container {
        }

        .dashboard-loader__container {
            z-index: 1;
        }
    }
    .redirect-dashboard{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }
}
