@import "../../../fonts/font-nunito-sans";

/* ===================
    Config
/* ===================*/
$debug-rem : true;

/* ===================
	    Colors (Same variables as Figma)
/* ===================*/

$primary-color      : #333333;
$primary-black      : $primary-color;
$secondary-01       : #FF9753;
$secondary-02       : #0072BA;
$secondary-03       : #003267;
$text-color         : $primary-color;

$white              : #fff;
$black              : #000;

$gray-01            : #616B75;
$gray-02            : #A3AAB2;
$gray-03            : #D7DCE1;
$gray-04            : #E7E9EA;

//$overlay-color      : #bbb;
//$line-color         : #bbb;

$success-color      : #80C936;
$danger-color       : #FBAA30;
$warning-color      : #ED4B46;

$gradient-primary  : linear-gradient(90deg, #0072BA 0%, #5AA2D7 74.11%);
$gradient-secondary: linear-gradient(180deg, #9A8274 0%, #B8A896 100%);
$gradient-background : linear-gradient(285.86deg, #0072BA 39.19%, #5AA2D7 97.64%);
$gradient-background-reverse : linear-gradient(90deg, #0072BA 39.19%, #5AA2D7 97.64%);


$box-shadow         : rem(0) rem(12) rem(20) 0 rgba($primary-black, 0.1);
$box-shadow-layout : rem(-4) 0 rem(15) rgba(0,0,0, 0.07);


/* ===================
	    Fonts
/* ===================*/
$font-primary : 'Nunito Sans', -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, sans-serif;
%letter-spacing {
    letter-spacing: 0.03em;
}


/* ===================
    Dauphine var default
/* ===================*/
$columns: false;
$sizing: false;
$grid: false;
$order: false;
$direction: false;
$display: false;
$spacing: false;

/* ===================
    FlexContent
/* ===================*/
$paddings: (
  xs : 40,
  sm : 60,
  lg : 80
);

/* ===================
    Animations
/* ===================*/
$animTranslation : 60px;
$translateTop : translate3d(0, $animTranslation, 0);
$animCurbe : cubic-bezier(0, 0, 0.58, 1);
$animTime : 400ms;