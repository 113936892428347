@import '../../assets/styles/core/helpers/inc';

.modal {

    position: fixed;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.6);
    max-height: 80vh;
    overflow: auto;

    display: flex;
    flex-direction: column;
    width: rem(572);
    border-radius: rem(8);
    padding: rem(56);

    background-color: $white;
    box-shadow: $box-shadow;
    transition: all 0.22s ease-in;

    &__close-button {
        position: absolute;
        right: rem(29);
        top: rem(29);
        fill: $gray-01;
        cursor: pointer;
    }

    &__header {
        width: 100%;

        padding-bottom: rem(16);

        &__icon {
            position: relative;
            padding: rem(10);
            padding-bottom: rem(16);
            height: rem(97);

            &__background {
                position: absolute;
                width: 88px;
                height: 88px;
                top: 50%;
                left: 50%;
                border-radius: 50%;
                transform: translate(-50%, -55%);
                z-index: 0;
                opacity: 0.15;
            }

            img {
                position: relative;
                max-height: rem(64);
            }
        }

        &__picture {
            width: rem(460);
            height: rem(260);
            background-size: cover;
            background-position: center;
            margin-bottom: rem(16);
        }

        &__video {
            margin-bottom: rem(16);
        }


    }

    &__headline {
        color: $gray-02 !important;
    }

    &__description {
        color: $gray-01;
    }

    &__date {
        color: $gray-02 !important;
        font-size: rem(14);
    }


    &__title {
        @extend %h2;
        margin: rem(5) 0 rem(10) 0;
    }

    &__content {
        margin-top: rem(16);
    }

    &__cta {
        margin-top: rem(16);
    }

    &:not(.modal__notification) {
        .modal__header {
            display: flex;
            flex-direction: row;
            text-align: left;
            align-items: center;
            border-bottom: 1px solid $gray-03;

            &__text {
                margin-left: rem(16);
            }
        }

        .modal__title {
            background: transparent !important;
            -webkit-background-clip: unset !important;
            -webkit-text-fill-color: unset !important;
            color: $primary-black;
        }
    }

    a:not(.btn) {
        color: $secondary-03;
    }
}

.modal-container {

    position: relative;
    opacity: 0;
    z-index: -1;
    transition: all 0.2s ease-in;

    &::before {
        content: "";
        position: fixed;
        z-index: 9;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $black;
        opacity: 0.6;
        cursor: pointer
    }

    &--show {
        z-index: 999;
        opacity: 1;

        .modal {
            transform: translate(-50%, -50%) scale(1);
        }
    }
}
